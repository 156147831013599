<template>
  <configurable-table
    :report="report"
  />
</template>

<script>
import { capital } from '@/api'
import ConfigurableTable from './ConfigurableTable'
import { Report } from './report'

export default {
  name: 'PageReportGoodsSelfIncome',
  components: {
    ConfigurableTable,
  },
  setup () {
    const queryFields = [
      {
        key: 'productName',
        type: 'input',
        title: '商品名称',
      },
      {
        type: 'daterange',
        key: 'range',
        title: '结算日期',
      },
    ]

    const resultFields = [
      {
        key: 'productName',
        title: '商品名称',
      },
      {
        key: 'sale',
        title: '销售数量',
      },
      {
        key: 'settleAmount',
        title: '订单金额',
      },
      {
        key: 'logisticsAmount',
        title: '运费',
      },
      {
        key: 'tradePrice',
        title: '供货价'
      },
      {
        key: 'serviceFee',
        title: '平台服务费'
      },
      {
        key: 'realAmount',
        title: '入账金额'
      }
    ]

    return {
      capital,
      report: new Report(queryFields, resultFields, {
        key: 'orderIncomeId',
        queryApi: ({ range, ...others }) => {
          return capital.getSelfGoodsIncomeReport({
            ...others,
            ...(range.length ? {
              settleBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
              settleEndTime: range[1].format('YYYY-MM-DD 23:59:59')
            } : null)
          })
        },
        exportApi: ({ range, ...others }) => {
          return capital.exportSelfGoodsIncomeReport({
            ...others,
            ...(range.length ? {
              settleBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
              settleEndTime: range[1].format('YYYY-MM-DD 23:59:59')
            } : null)
          })
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
