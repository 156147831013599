<template>
  <div class="page-box">
    <slot name="total" />

    <base-screen>
      <a-form-model layout="inline" :model="query">
        <template v-for="f in report.queryFields">
          <a-form-model-item v-if="!f.hidden" :key="f.key" :label="f.title" :prop="f.key">
            <search-field v-if="f.type === 'search'" :f="f" v-model="query[f.key]"></search-field>
            <a-input v-else-if="f.type === 'input'" :placeholder="f.placeholder" v-model="query[f.key]"></a-input>
            <date-range-field v-else-if="f.type === 'daterange'" v-model="query[f.key]" :f="f"></date-range-field>
            <select-field v-else-if="f.type === 'select'" v-model="query[f.key]" :f="f"></select-field>
          </a-form-model-item>
        </template>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <div v-if="report.exportApi" class="tr" style="padding-bottom: 15px;">
      <a-button :loading="exporting" @click="exportFn">导出</a-button>
    </div>

    <base-table
      :columns="report.resultFieldsPlain"
      :row-key="report.key"
      :data-source="data"
      :loading="loading"
      :pagination="page"
      @change="handleTableChange"
      :scroll="{x: 'auto'}"
      class="nowrap"
    ></base-table>
  </div>
</template>

<script>
import { onMounted, ref, shallowReactive, watch } from '@vue/composition-api'
import { Report } from './report'
import SearchField from './SearchField'
import DateRangeField from './DateRangeField'
import SelectField from './SelectField'
import { useExport } from '@/utils/tools'

export default {
  name: 'ConfigurableTable',
  components: {
    SearchField,
    DateRangeField,
    SelectField,
  },
  props: {
    report: Report
  },
  setup (props) {
    const query = ref(props.report.initialQuery)
    const data = ref([])
    const loading = ref(false)
    const page = shallowReactive({
      current: 1,
      size: 10,
      total: 0
    })

    onMounted(handleQuery)

    async function handleQuery () {
      loading.value = true
      const res = await props.report.query({
        ...query.value,
        current: page.current,
        size: page.size,
      })
      loading.value = false
      data.value = res.data
      page.total = res.page.total
    }

    function handleReset () {
      query.value = props.report.initialQuery
      handleQuery()
    }

    const { exportFn, exporting } = useExport(() => props.report.exportApi({
      ...query.value
    }))

    function handleTableChange ({ current }) {
      page.current = current
    }

    watch(() => page.current, value => {
      handleQuery()
    })

    return {
      query,
      handleQuery,
      handleReset,
      loading,
      data,
      page,
      exportFn,
      exporting,
      handleTableChange,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
